@import "./reset.scss";

html {
  margin: 0;
  padding: 0;
  height: 100%;

  body {
    @import "./components/custom-calendar.scss";
    @import "./components/stripe.scss";
    @import "./components/strapi-editorial.scss";
    @import "./components/splide.pagination.scss";


    ::-moz-selection {
      color: whitesmoke;
      background: #fcb03c;
    }

    ::selection {
      color: rgb(37, 36, 36);
      background: #fcb03c;
    }


    scroll-snap-type: y proximity;
    scroll-behavior: auto !important;
    overflow-x: hidden !important;

    :focus-visible {
      outline: none !important;
    }

    .simplebar-content {
      padding: 0 !important;
    }



    .MuiTabs-scrollButtons.Mui-disabled {
      opacity: 0.3;
    }


    .clamp {
      display: -webkit-box;
      -webkit-line-clamp: 4;
      -webkit-box-orient: vertical;
      overflow: hidden;
    }



    video {
      pointer-events: none !important;

      &::-webkit-media-controls {
        display: none !important;
      }

      &::-webkit-media-controls-start-playback-button {
        display: none !important;
      }
    }


  }
}