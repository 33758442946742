.custom-calendar {
  &.MuiPickerStaticWrapper-root {
    .MuiPickerStaticWrapper-content {
      background-color: transparent !important;

      .MuiCalendarOrClockPicker-root {
        .css-epd502 {
          width: 100% !important;
        }
        .MuiDayPicker-header,
        .MuiDayPicker-weekContainer {
          justify-content: space-around !important;
        }
      }
    }
  }
}
