 .strapi-editorial {

     h1,
     h2,
     h3,
     h4,
     h5 {
         // color: rgb(250, 175, 64);
         font-size: 1.3rem;
         //margin: 1.5rem 0;
         margin: 0 0 1.5rem 0;
         font-weight: 500;
         font-family: Jost, sans-serif !important;
     }

     pre {
         margin: 1rem;

         code {
             font-family: Jost, sans-serif !important;
             text-transform: capitalize;
         }
     }

     p {
         margin: 1rem 0 1.5rem 0;

         strong {
             font-weight: 600;

             strong {
                 font-weight: 600;
             }
         }
     }
 }

 .strapi-header-editorial {
     p {
         margin: 0 0 0.25rem 0;
         //font-size: 1.1rem;

         strong {
             font-weight: 600;

             strong {
                 font-weight: 600;
             }
         }
     }
 }