 .splide__pagination {
     bottom: -40px !important;
 }

 .splide__arrows {
     &.splide__arrows--ltr {
         bottom: -30px !important;
         position: absolute !important;
         width: 100%;
         right: 0px !important;
         left: 0px !important;
         // height: 100%;

         button.splide__arrow {
             height: 100%;

             svg {
                 fill: rgb(250, 175, 64);
             }

         }
     }
 }

 .splide__pagination__page {
     background-color: #ececec;

     @media screen and (max-width: 768px) {
         height: 6px;
         width: 6px;
         margin: 2px;
     }



     &.is-active {
         background: rgb(250, 175, 64) !important;
         opacity: 1;

         @media screen and (max-width: 768px) {
             transform: scale(0.9);
         }


         transform: scale(1.1);
     }
 }